import React, { Component } from 'react';

export default class About extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="about">
                    <div className="row">
                        <div className="three columns">
                            <img className="profile-pic" src="images/rod-oliveira.png" alt="profile" />
                        </div>
                        <div className="nine columns main-col">
                            <h2>About Me</h2>
                            <p>
                                My name is João Rodolfo Suárez de Oliveira, also known as Rod Oliveira. Based in
                                Halifax, Canada, I specialize in back-end development, with expertise in Java,
                                Spring Boot, Microservices, AWS Cloud, and open-source solutions for system
                                integration, such as Apache Kafka and Apache Camel. Additionally, I am an
                                AI enthusiast, holding certifications in artificial intelligence and possessing
                                experience in implementing Retrieval Augmented Generation (RAG).
                            </p>
                            <div className="row">
                                <div className="columns contact-details">
                                    <h2>Contact Details</h2>
                                    <p className="address">
                                        <span>Rod Oliveira</span>
                                        <br />
                                        <span>
                                            Halifax, Canada<br />
                                        </span>
                                        <span>jrodolfo@gmail.com</span>
                                    </p>
                                </div>
                                <div className="columns download">
                                    <p>
                                        <a target="_blank" rel="noopener noreferrer" href="/pdf/rod-oliveira-resume.pdf" className="button">
                                            <i className="fa fa-download" />Download Resume
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
